import {
  UPDATE_DIETITIAN_SUCCESS,
  UPDATE_DIETITIAN_FAIL,
  GET_DIETITIAN_SUCCESS,
  GET_DIETITIAN_FAIL,
  GET_DIETITIANS_SUCCESS,
  GET_DIETITIANS_FAIL,
  SET_DIETITIAN_LOADING,
  CLEAR_DIETITIAN_ERROR,
  GET_DIETITIAN_APPOINTMENTS_SUCCESS,
  GET_DIETITIAN_APPOINTMENTS_FAIL,
  GET_DIETITIAN_AVAILABLE_SUCCESS,
  GET_DIETITIANS_AVAILABLE_FAIL,
  GET_PATIENT_APPOINTMENTS_SUCCESS,
  GET_PATIENT_APPOINTMENTS_FAIL,
} from './types';

const initialState = {
  loading: false,
  dietitian: null,
  dietitians: [],
};

const dietitianReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_DIETITIAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_DIETITIAN_ERROR:
      return {
        ...state,
        error: null,
        success: null,
      };

    // user login and registration success
    case GET_DIETITIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dietitian: action.payload,
      };

    case GET_DIETITIANS_SUCCESS:
      return {
        dietitians: action.payload,
        loading: false,
        error: false,
      };

    case UPDATE_DIETITIAN_SUCCESS:
      return {
        ...state,
        dietitian: action.payload,
        loading: false,
        updated: true,
      };
    case GET_DIETITIAN_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
        loading: false,
      };

    case GET_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload,
        loading: false,
      };

    case GET_DIETITIAN_AVAILABLE_SUCCESS:
      return {
        ...state,
        availableTimes: action.payload,
        loading: false,
      };
    // user auth error and logout
    case GET_DIETITIAN_FAIL:
    case GET_DIETITIANS_FAIL:
    case UPDATE_DIETITIAN_FAIL:
    case GET_DIETITIAN_APPOINTMENTS_FAIL:
    case GET_DIETITIANS_AVAILABLE_FAIL:
    case GET_PATIENT_APPOINTMENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dietitianReducer;
