import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep } from '../../actions/moduleActions';
import { useTranslation } from 'react-i18next';
import ContactHelp from './ContactHelp';
import NavigationPatient from './NavigationPatientHeader';
import { ButtonNavBar } from './buttonNavBar';

function PublicUserPreviewModulePage({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { patient } = useSelector((state) => state.patientR);
  const { language } = useSelector((state) => state.fontR);
  const { module } = useSelector((state) => state.moduleR);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');

    if (step) {
      dispatch(
        getModuleByStep({ step, language: language ? language : 'malay' })
      );
    }
  }, [dispatch, language]);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative mb-4">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {module?.step < 3 ? t('Trial_Module') : t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>
        <div className="mx-3">
          <div>
            {t('Welcome! This module is exclusively available to')}{' '}
            <span className="fw-bold">{t('patients appointed')}</span>{' '}
            {t('by our partner doctors')}.
          </div>
          <div className="mt-3">{t('To access these modules')}</div>
        </div>
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
}

export default PublicUserPreviewModulePage;
