import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { tokenConfig } from '../../actions/actionHelperFunc';
import privacyPolicy from '../../images/DLP Pt Web App Privacy Policy & Terms of Use 20241202.pdf';
import privacyPolicyBM from '../../images/DLP Pt Web App Privacy Policy & Terms of Use 20241202 (BM).pdf';

const PrivacyPage = ({ history }) => {
  const { t } = useTranslation();

  const [consent, setConsent] = useState(false);
  const [phoneNumber] = useState(localStorage.getItem('patient_Number'));
  const [inputError, setInputError] = useState(null);
  const { language } = useSelector((state) => state.fontR);

  const handleClose = (e) => {
    e.preventDefault();

    const data = {
      phoneNumber: phoneNumber,
      consent: consent,
    };

    axios
      .post('/api/patient/updatePatient', data, tokenConfig())
      .then((res) => {
        history.push('/patient/ftr_doctor_info');
      })
      .catch((err) => {
        setInputError(err.response.data.error);
      });
  };

  // console.log('language', language);

  return (
    <div>
      <form onSubmit={handleClose}>
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          className="dc-disclaimer-consent-modal pt-disclaimer-consent-modal"
          style={{ opacity: '1' }}
        >
          <Modal.Header>
            <Modal.Title> {t('disclaimer')} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row dc-disclaimer-consent-modal-top-body pt-disclaimer-consent-modal-top-body">
              <div className="col">
                <p>{t('By_Using_The_Web_Based_App')}</p>
              </div>
            </div>
            <form id="dc-close-modal-tnc">
              <div className="row dc-disclaimer-consent-modal-bot-body pt-disclaimer-consent-modal-bot-body">
                <div className="col">
                  <div className="d-flex">
                    <Form.Check
                      value={consent}
                      onChange={(e) => setConsent(!consent)}
                      aria-label="option 1"
                      required
                    />
                    <p>
                      {t('i_acknowledge')}{' '}
                      <a
                        href={
                          language === 'english'
                            ? privacyPolicy
                            : privacyPolicyBM
                        }
                        target="_blank"
                        style={{
                          textDecoration: 'underline',
                          color: '#b5893f',
                        }}
                        rel="noreferrer"
                      >
                        {t('privacyAndTerms')}
                      </a>
                    </p>
                  </div>
                  <div className="py-3">
                    <i>{t('disclaimer_description')} </i>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer style={{ border: 'none' }}>
            <Button form="dc-close-modal-tnc" type="submit" variant="secondary">
              {t('Proceed')}
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default PrivacyPage;
