import {
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAIL,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  SET_PATIENT_LOADING,
  CLEAR_PATIENT_ERROR,
  GET_PATIENT_TRACKER_SUCCESS,
  GET_PATIENT_TRACKER_FAIL,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAIL,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAIL,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAIL,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
} from './types';

const initialState = {
  loading: false,
  patient: null,
  patients: [],
  success: null,
  patientTracker: [],
  appointment: null,
};

const patientReducer = function (state = initialState, action) {
  // console.log(action);
  // console.log(state.patients);
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_PATIENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_PATIENT_ERROR:
      return {
        ...state,
        error: null,
        success: null,
      };

    // user login and registration success
    case GET_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        patient: action.payload,
        phoneNumber: action.payload.phoneNumber,
      };
    case GET_PATIENT_TRACKER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        patientTracker: action.payload.data,
        moduleCompletion: action.payload.moduleCompletion,
      };

    case GET_PATIENTS_SUCCESS:
      return {
        patients: action.payload,
        loading: false,
        error: false,
        success: true,
      };

    case UPDATE_PATIENT_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        patient: action.payload,
        loading: false,
        updated: true,
      };

    case BOOK_APPOINTMENT_SUCCESS:
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
      };
    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointment: action.payload,
        appointmentUpdated: true,
      };

    case GET_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: {
          ...state.patient,
          address: action.payload.address,
        },
        success: true,
      };
    case GET_ADDRESS_FAIL:
    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // user auth error and logout
    case GET_PATIENT_FAIL:
    case GET_PATIENTS_FAIL:
    case UPDATE_PATIENT_FAIL:
    case GET_PATIENT_TRACKER_FAIL:
      return {
        loading: false,
        updated: false,
        error: action.payload,
      };
    case BOOK_APPOINTMENT_FAIL:
    case UPDATE_APPOINTMENT_FAIL:
    case GET_APPOINTMENT_FAIL:
      return {
        ...state,
        loading: false,
        updated: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default patientReducer;
