import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsWhatsapp } from 'react-icons/bs';
import ReactWhatsapp from 'react-whatsapp';
import { getAppointmentById } from '../../actions/patientActions';

const VideoWhereby = ({ history }) => {
  const dispatch = useDispatch();
  const [roomUrl, setRoomUrl] = useState('');
  const [appointmentId, setAppointmentId] = useState('');
  const [error, setError] = useState('');
  const { appointment } = useSelector((state) => state.patientR);
  const urlParams = new URLSearchParams(window.location.search);
  const userType = urlParams.get('userType');

  const userCount = useRef(1);
  // console.log('appointment', appointment);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const appointmentId = urlParams.get('appointmentId');

    if (appointmentId) {
      setAppointmentId(appointmentId);
      dispatch(getAppointmentById({ appointmentId }));
    }
  }, [dispatch]);
  // console.log('roomUrl', roomUrl);

  useEffect(() => {
    if (appointment) {
      if (appointment.name) {
        setRoomUrl(appointment.name);
      } else {
        axios
          .post('/api/room/create/whereby-room', {
            appointmentId: appointment.id,
          })
          .then((res) => {
            // console.log('res', res.data.data);
            setRoomUrl(res.data.data);
          })
          .catch((err) => {
            // console.log('err.response.data.error', err.response.data);
            setError(err.response.data.error);
          });
      }
    }
  }, [appointment]);
  // console.log('appointment', appointment);

  useEffect(() => {
    if (roomUrl) {
      const elm = document.querySelector('whereby-embed');
      // console.log('elm', elm);

      // Add event listener for when participants join the room
      elm.addEventListener('join', (participant) => {
        // You can perform additional actions here
        const body = {
          appointmentId: appointmentId,
        };
        if (userType === 'dietitian') {
          localStorage.setItem('dietitian_joined', new Date());
          body.dietitian_show_up_time = new Date();
        } else {
          localStorage.setItem('patient_joined', new Date());
          body.patient_show_up_time = new Date();
        }

        // console.log('body', body);
        // console.log('durationString -----', durationString);
        axios.post('/api/room/updateRoomStartTime', body);
      });

      elm.addEventListener('participant_join', function (data) {
        // console.log('participant joined');
        userCount.current = 2;
      });

      elm.addEventListener('participant_leave', function (data) {
        // Calculate the duration in milliseconds

        const body = {
          appointmentId: appointmentId,
          status: userCount.current > 1 ? 'Completed' : 'Initiated',
          patientId: appointment.patientId,
        };

        // console.log('participant_leave', body);
        axios.post('/api/room/updateRoomStatus', body);
        // userCount.current = userCount.current > 1 ? 1 : 0;
      });

      elm.addEventListener('leave', function (data) {
        const body = {
          appointmentId: appointmentId,
          status: userCount.current > 1 ? 'Completed' : 'Initiated',
          patientId: appointment.patientId,
        };

        // console.log('leave', body, userCount);
        axios.post('/api/room/updateRoomStatus', body);
        // if (userCount.current < 2) {
        //   axios.post('/api/room/updateRoomStatus', body);
        //   history.go(-1);
        // }
      });
    }
  }, [roomUrl]);

  // console.log('appointment', intervalId, duration);

  return (
    <div style={{ backgroundColor: '#006653' }}>
      <div style={{ height: '95vh' }}>
        {roomUrl ? (
          <whereby-embed
            room={roomUrl}
            leaveButton
            screenshare
            floatSelf="off"
            settingsButton="off"
            pipButton="off"
            moreButton="off"
            autoSpotlight
          ></whereby-embed>
        ) : (
          <h4 className="text-center text-white py-5">No video room found!</h4>
        )}
      </div>

      <div
        style={{ background: roomUrl ? '#006653' : 'transparent' }}
        className="px-3"
      >
        <ReactWhatsapp
          number="+601154085382"
          style={{ border: 'none', background: 'transparent' }}
        >
          <div style={{ color: '#128C7E' }}>
            <BsWhatsapp size={'2.5rem'} />
            <div style={{ color: '#fff' }}>hotline</div>
          </div>
        </ReactWhatsapp>
      </div>
    </div>
  );
};

export default VideoWhereby;
