import React, { useEffect, useState } from 'react';
import PublicHeader from '../../components/PublicHeader';
import '../../css/patient.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useSelector } from 'react-redux';
import alertIcon from '../../images/DLP_revamp_asset/Icon/alert-icon-1.png';
import TrailCompletionModal from '../../components/TrailCompletionModal';
import axios from 'axios';
import { tokenConfig } from '../../actions/actionHelperFunc';
import { useTranslation } from 'react-i18next';
import ContactHelp from './ContactHelp';
import { ButtonNavBar } from './buttonNavBar';

const PatientPublicUserHomePage = ({ history }) => {
  const { t } = useTranslation();

  const { patient } = useSelector((state) => state.patientR);
  const [modules, setModules] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [completed, setCompleted] = useState(false);

  const patientLang = localStorage.getItem('patientLanguage');
  const language = patientLang === 'english' ? 'english' : 'malay';

  useEffect(() => {
    if (patient) {
      axios
        .post(
          `/api/module/public/getModules`,
          { patientId: patient.id, language },
          tokenConfig()
        )
        .then((res) => {
          const modulesData = res.data.data;

          if (res.data.trialModuleCompletion) {
            setCompleted(true);
          }
          if (modulesData) {
            setModules(modulesData);
          }
        })
        .catch((err) => {
          console.log('err', err.response);
        });
    }
  }, [patient, language]);

  // console.log('modules', modules);

  return (
    <div
      className="main-wrapper-div"
      style={{ maxWidth: '500px', margin: '0 auto' }}
    >
      <TrailCompletionModal
        setShowModal={setShowModal}
        patientId={patient?.id}
        show={showModal}
      />
      <div className="public-user-header-bg-img">
        <PublicHeader
          history={history}
          userName={patient?.firstName}
          backBtn={true}
        />
      </div>

      <div className="main-body-height">
        {completed ? (
          <div
            className="d-flex align-items-center mt-2"
            style={{ backgroundColor: '#D0EB9A' }}
          >
            <img src={alertIcon} alt="alertIcon" />
            <b>{t('You_have_completed_all_trial_modules')}</b>
          </div>
        ) : null}

        {modules && modules.length
          ? modules.map((module, idx) => {
              return (
                <div
                  onClick={() => {
                    if (idx < 2) {
                      return history.push(
                        `/patient/learn module/main/preview?step=${
                          idx + 1
                        }&&patientId=${patient?.id}`
                      );
                    } else {
                      if (completed) {
                        setShowModal(true);
                      }
                    }
                  }}
                  key={idx}
                  className="module-container mt-2"
                >
                  <div
                    style={{
                      backgroundImage: `url(${module.photoUrl})`,
                      backgroundColor: idx < 2 ? '#ede8d1' : '#eed2e4',
                      filter: `grayscale(${idx < 2 ? 0 : 1})`,
                    }}
                    className="module-header-bg-img"
                  ></div>

                  <div className="position-relative ms-3">
                    <div className="module-header-title">
                      {idx < 2 ? t('Trial_Module') : t('Registered_Patient')}
                    </div>
                    <div className="py-3 module-main-header">
                      {module.titleSecond}
                    </div>
                    {idx < 2 ? (
                      <div
                        className="module-header-tracker"
                        style={{ width: '55%' }}
                      >
                        <div className="d-flex align-items-center">
                          <ProgressBar
                            style={{
                              height: '0.5rem',
                              borderRadius: '2rem',
                              width: '80%',
                            }}
                            now={
                              module.completedPercent == '3'
                                ? '100'
                                : module.completedPercent * 33.33
                            }
                            variant="success"
                          />
                          <span className="ms-3">
                            {module.completedPercent}/3
                          </span>
                        </div>
                        <div> {t(`You're on track!`)} </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <ButtonNavBar publicPatient={true} />
      <ContactHelp />
    </div>
  );
};

export default PatientPublicUserHomePage;
