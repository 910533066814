import {
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAIL,
  GET_MODULE_SUCCESS,
  GET_MODULE_FAIL,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  SET_MODULE_LOADING,
  CLEAR_MODULE_ERROR,
  ADD_MODULE_ANSWER_SUCCESS,
  ADD_MODULE_ANSWER_FAIL,
  GET_MODULE_ANSWER_SUCCESS,
  GET_MODULE_ANSWER_FAIL,
  GET_MODULE_QUESTIONS_SUCCESS,
  GET_MODULE_QUESTIONS_FAIL,
  GET_MODULE_RE_QS_SUCCESS,
  GET_MODULE_RE_QS_FAIL,
  GET_DUAL_MODULE_QUESTIONS_SUCCESS,
  GET_DUAL_MODULE_QUESTIONS_FAIL,
} from './types';

const initialState = {
  loading: false,
  module: null,
  modules: [],
  moduleAnswer: null,
  moduleAnswers: [],
  questions: [],
  answerArray: null,
  dualModules: [],
};

const moduleReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_MODULE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_MODULE_ERROR:
      return {
        ...state,
        error: null,
        success: null,
      };

    // user login and registration success
    case GET_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        module: action.payload.module,
        moduleTracker: action.payload.moduleTracker,
      };

    case GET_MODULE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        moduleAnswer: action.payload,
        answerArray: action.payload,
      };

    case ADD_MODULE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        moduleAnswer: action.payload,
        answerArray: action.payload,
      };
    case GET_MODULE_RE_QS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newQuestions: action.payload,
      };
    case GET_MODULE_QUESTIONS_SUCCESS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        loading: false,
        error: false,
        questions: action.payload,
      };

    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
        loading: false,
        error: false,
      };
    case GET_DUAL_MODULE_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dualModules: action.payload,
      };

    case UPDATE_MODULE_SUCCESS:
      return {
        ...state,
        module: action.payload,
        loading: false,
        updated: true,
      };

    // user auth error and logout
    case GET_MODULE_RE_QS_FAIL:
    case GET_MODULE_FAIL:
    case GET_MODULES_FAIL:
    case UPDATE_MODULE_FAIL:
    case ADD_MODULE_ANSWER_FAIL:
    case GET_MODULE_ANSWER_FAIL:
    case GET_MODULE_QUESTIONS_FAIL:
    case GET_DUAL_MODULE_QUESTIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
        questions: [],
        dualModules: [],
      };

    default:
      return state;
  }
};

export default moduleReducer;
