export const returnError = (error) => {
  if (error.response) {
    if (error.response.data) {
      if (
        error.response.data.error === 'Action Denied, Unauthorized' ||
        error.response.data.error === 'Token is not valid'
      ) {
        // console.log('error.response.data', error.response.data.error);
        setTimeout(() => {
          localStorage.removeItem('dlp-user-token');
          localStorage.removeItem('patient_Number');
          localStorage.removeItem('patient_id');
          localStorage.removeItem('doctor_id');
          localStorage.getItem('doctor_Number');
          localStorage.getItem('dietitian_Number');
          localStorage.getItem('keep-me-login-dietitian');
          localStorage.getItem('keep-me-login-doctor');

          return (window.location.href = '/');
        }, 2000);
      } else {
        return error.response.data.error;
      }
    }
  } else {
    return 'Internal Error';
  }
};

export const returnConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

// Setup config/headers and token
export const tokenConfig = (getState, admin) => {
  const urlParams = window.location.pathname;
  const adminUser = urlParams.includes('admin');

  let token;
  if (adminUser) {
    token = localStorage.getItem('dlp-admin-token');
  } else {
    token = localStorage.getItem('dlp-user-token');
  }

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  //if there is a token then add it to the header
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  // console.log('config: ', config);

  return config;
};
