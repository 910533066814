import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleByStep } from '../../../actions/moduleActions';
import '../../../index.css';
import '../../../css/patient_profile.css';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';
import { Link } from 'react-router-dom';
import wonderful from '../../../images/DLP_revamp_asset/Icon/wonderful-icon.png';

function VideoCompletionPage({ btnColor, image, btnText, background }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { patient } = useSelector((state) => state.patientR);
  const { language } = useSelector((state) => state.fontR);
  const { module } = useSelector((state) => state.moduleR);
  const [rewatch, setRewatch] = useState(false);
  const [actionLink, setActionLink] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get('step');

  useEffect(() => {
    const patientId = urlParams.get('patientId');
    const getRewatch = urlParams.get('rewatch');

    if (step) {
      if (getRewatch === 'true') {
        setRewatch(true);
        setActionLink(`/patient/learn module/main`);
      } else {
        if (step == 3) {
          setActionLink(
            `/patient/learn_module/module3/action?step=${step}&&patientId=${patientId}`
          );
        } else if (step == 4) {
          setActionLink(
            `/patient/learn_module/module4/action/main?step=${step}&&patientId=${patientId}`
          );
        } else {
          setActionLink(
            `/patient/learn_module/action?step=${step}&&patientId=${patientId}`
          );
        }
      }

      dispatch(
        getModuleByStep({ step, language: language ? language : 'malay' })
      );
    }
  }, [dispatch, language]);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {module?.step < 3 ? t('Trial_Module') : t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div className="mx-3">
          <div className="sv_progress_bar_block">
            <div className="green-background"></div>
            <div className="green-background mx-3"></div>
            <div className="gray-background"></div>
          </div>

          <center className="mt-4">
            <img src={wonderful} style={{ width: '14rem' }} alt="success" />
          </center>
          <div>
            <div className="mx-5 text-center  py-3">
              <h3 className="fw-bold">
                {rewatch ? (
                  t('rewatch_video')
                ) : (
                  <span>
                    {step == '2' ? (
                      t('complete_watch_video')
                    ) : (
                      <>"{t('complete_watch_video')}" </>
                    )}
                  </span>
                )}
              </h3>
            </div>

            <div>
              {rewatch ? (
                <Link
                  to={
                    patient?.public_registered_user && !patient?.doctorId
                      ? '/patient/public/home'
                      : '/patient/learn module/main'
                  }
                >
                  <button className="custom-primary-button">{t('Okay')}</button>
                </Link>
              ) : (
                <>
                  <Link
                    onClick={() =>
                      localStorage.setItem('homepageNotification', 'true')
                    }
                    to={actionLink}
                  >
                    <button className="custom-primary-button">
                      {t('Proceed')}
                    </button>
                  </Link>
                  <Link
                    to={
                      patient?.public_registered_user && !patient?.doctorId
                        ? '/patient/public/home'
                        : '/patient/learn module/main'
                    }
                  >
                    <button className="custom-transparent-button mt-2">
                      {t('MaybeLater')}
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
}

export default VideoCompletionPage;
