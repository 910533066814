import React from 'react';
import logoWhite from '../images/DLP_revamp_asset/logo-header/white-logo-header.png';
import backBtnIcon from '../images/DLP_revamp_asset/Icon/white-back-btn.png';
import { t } from 'i18next';

const PublicHeader = ({ userName, backBtn, history }) => {
  return (
    <div className="ms-4">
      <div className="d-flex justify-content-between me-3">
        <div className="pt-4">
          {backBtn ? (
            <img
              style={{ width: '1.6rem' }}
              src={backBtnIcon}
              alt="backBtnIcon"
              onClick={() => history.goBack()}
            />
          ) : null}
        </div>
        <div className="pt-3">
          <img src={logoWhite} alt="logoWhite" />
        </div>
      </div>
      {userName ? (
        <div className="patient-header-text mt-3">
          <div>
            {t('Hello')} , {userName}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PublicHeader;
