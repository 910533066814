import React from 'react';

const Loader = () => {
  return (
    <div className="text-center">
      <div
        style={{ width: '50px', height: '50px' }}
        className={'spinner-border text-primary'}
      ></div>
    </div>
  );
};

export default Loader;
