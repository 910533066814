import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import showdown from 'showdown';
import {
  getModuleByStep,
  answerModuleQuestion,
  getModuleQuestionsByType,
} from '../../../actions/moduleActions';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import '../../../index.css';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';

function ActionModule2({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.fontR);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    const patientId = urlParams.get('patientId');
    if (patientId) {
      setPatientId(patientId);
    }
    if (step) {
      setStep(Number(step));
      dispatch(getModuleByStep({ step, myType: 'action', language }));
      dispatch(getModuleQuestionsByType({ step, myType: 'action', language }));
    }
  }, [language, dispatch]);

  const { module, questions, moduleAnswer, loading } = useSelector(
    (state) => state.moduleR
  );
  const [allQuestions, setAllQuestions] = useState({
    requiredText: '',
    pages: [],
    showQuestionNumbers: 'off',
  });
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [questionLength, setQuestionLength] = useState(0);
  const [allQArr, setAllQArr] = useState({});
  const [answerArr, setAnswerArr] = useState({});
  const [message, setMessage] = useState('');
  const [currentQLength, setCurrentQLength] = useState(0);

  // console.log('questions',questions.length)

  useEffect(() => {
    setMessage('');
    let questionsFiltered = [];
    if (questions && questions.length) {
      let json = {
        requiredText: '',
        pages: [],
        showQuestionNumbers: 'off',
      };
      questionsFiltered = questions;

      const newArr = {};
      const allR = [];
      questionsFiltered.forEach((q, index) => {
        newArr[index] = {}; // set answer object
        allR.push(index);
        let sortedD;
        if (q.length > 1) {
          sortedD = q.sort((a, b) => a.rank - b.rank);
        } else {
          sortedD = [q];
        }
        json.pages.push({ questions: sortedD });
      });

      setAllQuestions(json);
      setAllQArr(json);
      setAnswerArr(newArr);

      setQuestionLength(json.pages && json.pages.length - 1);
      if (json.pages.length) {
        setCurrentQLength(json.pages[0] && json.pages[0].questions.length);
        setCurrentQuestion(json.pages[0] && json.pages[0].questions[0]);
      }
    }
  }, [questions]);

  useEffect(() => {
    if (moduleAnswer && submitted) {
      return (window.location.href =
        '/patient/action/completion' + window.location.search);
    }
  }, [moduleAnswer, submitted]);

  var survey = new Survey.Model(allQuestions);
  survey.locale = language === 'malay' ? 'id' : 'en';
  Survey.StylesManager.applyTheme('modern');

  // "renderAs": "radiogroup-with-image",

  survey.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;
    // console.log('options.question.getType()', options.question.getType());
    classes.mainRoot += ' sv_qstn-action';
    classes.root = ' sq-root-action';
    classes.title += ' sq-title';
    classes.item += ' sq-item';
    classes.label += ' sq-label';
    classes.number += ' action-question_num';
    classes.description += ' sv_action_description';

    if (options.question.getType() === 'rating') {
      classes.description +=
        ' sv_action_description custom-module2-description';
    }

    if (options.question.getType() === 'text') {
      classes.root += ' sq-root-m5';
    }
    if (options.question.isRequired) {
      classes.title += ' sq-title-required';
      classes.root += ' sq-root-required';
    }
  });

  //Create showdown markdown converter
  var converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);

  survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    // str = str.substring(3);
    // str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  function onComplete() {
    const answerArrNew = localStorage.getItem('module-ans');
    // console.log('answerArr aaa', answerArrNew);
    const newD = JSON.parse(answerArrNew);
    // console.log('answerArr aaa2', JSON.parse(answerArrNew));
    if (Object.keys(newD)) {
      let newAnswer = {};
      Object.keys(newD).forEach(function (key) {
        newAnswer = { ...newAnswer, ...JSON.parse(newD[key]) };
      });
      // console.log('hit here inside func');
      let data = {
        step: step,
        patientId: patientId, // replace this id with real user id
        answer: JSON.stringify(newAnswer),
        lastQuestion: true,
        myType: 'action',
        language: language,
      };
      setSubmitted(true);
      dispatch(answerModuleQuestion(data));
    }
  }

  // console.log('answerArr out', answerArr);

  useEffect(() => {
    const data =
      currentStep && Object.keys(allQArr).length && allQArr.pages[currentStep];
    if (data) {
      setCurrentQLength(data.questions.length);
      setCurrentQuestion(data.questions[0]);
      setAllQuestions({
        requiredText: '',
        pages: [data],
        showQuestionNumbers: 'off',
      });
    }
  }, [currentStep, allQArr]);

  function nextAndBackPage() {
    setMessage('');
    if (currentStep === 0) {
      // only first page
      const answer = JSON.stringify(survey.data, null, 3);
      const newAnswer = JSON.parse(answer);

      if (Object.keys(newAnswer).length !== currentQLength) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return setMessage(
          language === 'english'
            ? 'Please fill all your answers.'
            : 'Sila isi semua jawapan anda.'
        );
      } else {
        let newAns = { ...answerArr };
        newAns[currentStep] = answer;
        setCurrentStep(currentStep + 1);

        setAnswerArr(newAns);
      }
    } else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      // set back to fist question
      if (currentStep - 1 === 0) {
        const data = allQArr.pages[0];
        setCurrentQuestion(data.questions[0]);
        setCurrentQLength(data.questions.length);
        setAllQuestions({
          requiredText: '',
          pages: [data],
          showQuestionNumbers: 'off',
        });
      }
    }
  }

  function nextAndComplete() {
    setMessage('');
    const answer = JSON.stringify(survey.data, null, 3);
    let newAns = { ...answerArr };
    const newAnswer = JSON.parse(answer);
    if (currentStep < questionLength) {
      if (Object.keys(newAnswer).length !== currentQLength) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        return setMessage(
          language === 'english'
            ? 'Please fill all your answers.'
            : 'Sila isi semua jawapan anda.'
        );
      } else {
        setCurrentStep(currentStep + 1);
        newAns[currentStep] = answer;
        setAnswerArr(newAns);
        localStorage.setItem('module-ans', JSON.stringify(newAns));
        onComplete();
      }
    } else {
      if (Object.keys(newAnswer).length !== currentQLength) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        return setMessage(
          language === 'english'
            ? 'Please fill all your answers.'
            : 'Sila isi semua jawapan anda.'
        );
      } else {
        newAns[currentStep] = answer;
        setAnswerArr(newAns);
        setCurrentStep(questionLength + 1);
        localStorage.setItem('module-ans', JSON.stringify(newAns));
        onComplete();
      }
    }
  }

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-body-height">
            <div className="pb-3 position-relative">
              <img
                src={module ? module.photoUrl : ''}
                style={{
                  width: '100%',
                  height: '12rem',
                  objectFit: 'cover',
                }}
                alt="header-img"
              />

              <div className="header-text-render mx-4">
                <div className="trial-header-text mb-2">
                  {module?.step < 3
                    ? t('Trial_Module')
                    : t('Registered_Patient')}
                </div>
                <div className="main-header-text">
                  {module && module.titleSecond}
                </div>
              </div>
            </div>

            <div className="mx-3">
              <div className="sv_progress_bar_block">
                <div className="green-background"></div>
                <div className="green-background mx-3"></div>
                <div className="gray-background"></div>
              </div>
              <h4 className="mt-3" style={{ textTransform: 'capitalize' }}>
                {currentQuestion && currentQuestion.headerTitle}
              </h4>
              <div>{currentQuestion && currentQuestion.mainQuestion}</div>
            </div>

            <div
              style={{
                width: '100%',
                borderRadius: '10px',
                position: 'relative',
              }}
            >
              {message ? (
                <div className="mx-2">
                  <Message variant="danger" fullWidth={true}>
                    {message}
                  </Message>
                </div>
              ) : null}
              {currentQuestion && currentQuestion.questionWithSubQuestions ? (
                <div
                  className=" mt-3 mx-2 fw-bold "
                  style={{ background: '#fff', borderRadius: '10px' }}
                >
                  <div className="p-2">
                    {currentQuestion &&
                      currentQuestion.questionWithSubQuestions}

                    {currentQuestion.questionWithSubQuestionsImage ? (
                      <div className="text-center">
                        <img
                          className="questionWithSubQuestionsImage"
                          src={
                            currentQuestion &&
                            currentQuestion.questionWithSubQuestionsImage
                          }
                          alt="questionWithSubQuestionsImage"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <Survey.Survey
                model={survey}
                showCompletedPage={false}
                showNavigationButtons={false}
              />

              <div className="mx-3 mb-3">
                <button
                  onClick={() => nextAndComplete()}
                  className="mt-2 custom-primary-button"
                >
                  {t('ContinueM')}
                </button>
              </div>
            </div>
          </div>

          <ButtonNavBar />
          <ContactHelp />
        </>
      )}
    </div>
  );
}

export default ActionModule2;
