import React from 'react';
import { useTranslation } from 'react-i18next';

import success from '../../images/DLP_revamp_asset/Icon/tick-circle.png';
import failed from '../../images/DLP_revamp_asset/Icon/cancel-icon.png';

export const ModalPatient = ({ isVisible, isSuccessful, onClose }) => {
  const { t } = useTranslation();
  if (!isVisible) return null;

  return (
    <div className="modal-patient mobile-view">
      <div className="modal-component">
        {isSuccessful ? (
          <>
            <img
              src={success}
              alt="success icon"
              style={{ marginBottom: '10px' }}
            />
            .
            <div
              style={{
                fontWeight: '700',
                fontSize: '1.3rem',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {t('Success')}
            </div>
            <p>{t('Success_Registered')}</p>
            <button className="custom-primary-button" onClick={onClose}>
              {t('Continue')}
            </button>
          </>
        ) : (
          <>
            <img
              src={failed}
              alt="failure icon"
              style={{ marginBottom: '10px' }}
            />
            <div
              style={{
                fontWeight: '700',
                fontSize: '1.3rem',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {t('Unsuccessful')}
            </div>
            <p style={{ fontWeight: '600 !important' }}>
              {t('Existed_Phone_Verify')}
            </p>
            <p style={{ fontWeight: '600', lineHeight: '0.1em' }}>
              {t('Phone_Verify')}
            </p>
            <button className="custom-primary-button" onClick={onClose}>
              {t('Close')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
