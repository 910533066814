//helpers/persistentData.js

/**
 * function to store single state in local storage
 */
 export const saveState = (state, data) => {
    try {
        // stringify the data as localstorage accepts string type data
        const serializedState = JSON.stringify(data)
        localStorage.setItem(state, serializedState)
    } catch {
        // ignore write errors
    }
}
/**
 * function to get single persistent state from localstorage
 */
export const loadState = (state) => {
    try {
      const serializedState = localStorage.getItem(state)
      if (serializedState === null) {
        return undefined
      }
      // parsing the stringify data
      return JSON.parse(serializedState)
    } catch (err) {
      return undefined
    }
}
