import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import showdown from 'showdown';
import {
  getModuleByStep,
  answerModuleQuestion,
  getModuleQuestionsByType,
} from '../../../actions/moduleActions';
import './SurveyComponent.css';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import Loader from '../../../components/Loader';
import ModuleFeedbackPage from './ModuleFeedbackPage';
import Message from '../../../components/Message';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';

function ActionModule1({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.fontR);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    const patientId = urlParams.get('patientId');
    if (patientId) {
      setPatientId(patientId);
    }
    if (step) {
      setStep(Number(step));
      dispatch(getModuleByStep({ step, myType: 'action', language }));
      dispatch(getModuleQuestionsByType({ step, myType: 'action', language }));
    }
  }, [language, dispatch]);

  const { module, questions, moduleAnswer, loading } = useSelector(
    (state) => state.moduleR
  );
  const [allQuestions, setAllQuestions] = useState({
    requiredText: '',
    pages: [],
    showQuestionNumbers: 'off',
  });
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentFeedback, setCurrentFeedback] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [questionLength, setQuestionLength] = useState(0);
  const [allQArr, setAllQArr] = useState({});
  const [showPopUpAction, setShowPopUpAction] = useState(false);
  const [answerArr, setAnswerArr] = useState({});
  const [allQs] = useState([0, 1, 2, 3, 4]);
  const [message, setMessage] = useState('');
  const [currentQLength, setCurrentQLength] = useState(0);
  const [lastQuestion, setLastQuestion] = useState(false);

  useEffect(() => {
    Survey.StylesManager.applyTheme('defaultV2');

    Survey.Serializer.addProperty('dropdown', {
      name: 'imageUrl:text',
      category: 'general',
    });
  }, []);

  useEffect(() => {
    setShowPopUpAction(false);
    setMessage('');
    let questionsFiltered = [];
    if (questions && questions.length) {
      let json = {
        requiredText: '',
        pages: [],
        showQuestionNumbers: 'off',
      };

      // Subheading names from backend
      const firstSubheading = {
        type: 'html',
        name: `module1actions2/1`,
        html:
          language === 'english'
            ? '<div class="sub-heading">Light intensity exercises: <span class="normal-text"> can talk & sing (eg casual walking, casual cycling, slow dancing)</span><div>'
            : '<div class="sub-heading">Senaman intensiti rendah: <span class="normal-text"> boleh bercakap & menyanyi (eg berjalan santai, berbasikal santai, menari perlahan)<span><div>',
      };

      const secondSubheading = {
        type: 'html',
        name: `module1actions2/3`,
        html:
          language === 'english'
            ? '<div class="sub-heading">Moderate <span class="normal-text"> (can talk but can’t sing) to</span> vigorous exercises <span class="normal-text"> (can only say a few words): (eg brisk walking, fast cycling, swimming laps, playing sports like badminton)</span>  <div>'
            : '<div class="sub-heading">Senaman intensiti sederhana <span class="normal-text"> (boleh bercakap tetapi tidak boleh menyanyi)</span> hingga tinggi <span class="normal-text"> (hanya boleh berkata sepatah-patah): (eg berjalan pantas, berbasikal pantas, berenang pusingan, bermain sukan seperti badminton) </span>  <div>',
      };

      const thirdSubheading = {
        type: 'html',
        name: `module1actions2/5`,
        html:
          language === 'english'
            ? '<div class="sub-heading">Strength training exercises <span class="normal-text"> (eg push-ups, weightlifting, sit-ups, squats)</span><div>'
            : '<div class="sub-heading">Senaman latihan kekuatan <span class="normal-text"> (eg tekan tubi, mengangkat berat, sit-up, squat)</span><div>',
      };

      const mapQuestionForQTwo = questions.map((q, idx) => {
        console.log('index', idx);
        if (idx === 1) {
          const addSubheading = [];
          q.sort((a, b) => a.rank - b.rank).forEach((each, index) => {
            if (index === 0) {
              addSubheading.push(firstSubheading);
              addSubheading.push(each);
            } else if (index === 2) {
              addSubheading.push(secondSubheading);
              addSubheading.push(each);
            } else if (index === 4) {
              addSubheading.push(thirdSubheading);
              addSubheading.push(each);
            } else {
              addSubheading.push(each);
            }
          });
          return addSubheading;
        } else {
          return q;
        }
      });

      questionsFiltered = mapQuestionForQTwo;

      const newArr = {};
      questionsFiltered.forEach((q, index) => {
        newArr[index] = {}; // set answer object
        let sortedD;
        if (q.length > 1) {
          sortedD = q.sort((a, b) => a.rank - b.rank);
        } else {
          sortedD = [q];
        }
        json.pages.push({ questions: sortedD });
      });

      setAllQuestions(json);
      setAllQArr(json);
      setAnswerArr(newArr);

      setQuestionLength(json.pages && json.pages.length - 1);
      if (json.pages.length) {
        setCurrentQLength(json.pages[0] && json.pages[0].questions.length);
        setCurrentQuestion(json.pages[0] && json.pages[0].questions[0]);
      }
    }
  }, [questions]);

  useEffect(() => {
    if (moduleAnswer && submitted) {
      return history.push(
        '/patient/action/completion' +
          window.location.search +
          `&questions=${questions.length}`
      );
    }
  }, [moduleAnswer, submitted, step]);

  // console.log('allQuestions', allQuestions);
  var survey = new Survey.Model(allQuestions);
  survey.locale = language === 'malay' ? 'id' : 'en';
  Survey.StylesManager.applyTheme('modern');
  // survey.locale = language === 'malay' ? 'id' : 'en';

  survey.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;

    // console.log('survey.currentPageNo', survey.currentPageNo, survey.pageCount);
    if (options.question.getType() === 'dropdown') {
      classes.header += ' custom-header-class';
      classes.mainRoot += ' sv_qstn-action';
      classes.root = ' sq-root-action';
      classes.title += ' sq-title custom-title';
      classes.item += ' sq-item';
      classes.label += ' sq-label';
      classes.number += ' action-question_num';
      classes.description += ' sv_action_description custom-description';
      classes.content += ' custom-content';
    } else {
      // Reset to default classes or customize other types as needed
      classes.mainRoot += ' sv_qstn-action';
      classes.root = ' sq-root-action';
      classes.title += ' sq-title';
      classes.item += ' sq-item';
      classes.label += ' sq-label';
      classes.number += ' action-question_num';
      classes.description += ' sv_action_description';
    }

    if (options.question.getType() === 'text') {
      classes.root += ' sq-root-m5';
    }
    if (options.question.isRequired) {
      classes.title += ' sq-title-required';
      classes.root += ' sq-root-required';
    }

    var questions = survey.getAllQuestions();
    var totalQuestions = questions.length;
    var questionIndex = questions.indexOf(options.question);

    if (currentStep === 4 && questionIndex >= totalQuestions - 3) {
      // Perform your custom actions for the last three questions
      classes.header += ' custom-header-class-m8';
      classes.title += ' sq-title custom-title-m8';
      classes.content += ' custom-content-m8';
      classes.description += ' sv_action_description custom-description-8';
    }
  });

  survey.onAfterRenderQuestion.add(function (survey, options) {
    var imgElement = options.htmlElement.querySelector('.sv-string-viewer img');
    if (imgElement) {
      imgElement.classList.add('custom-img');
    }
    if (currentStep === 4) {
      const elemental1 = options.htmlElement.querySelectorAll(
        '.sv_action_description .sv-string-viewer'
      );
      console.log(elemental1);

      // Loop through each element and set its font size
      elemental1.forEach(function (el) {
        el.style.fontSize = '10px';
        el.style.setProperty(
          'font-family',
          'Brandon-Grotesque-Regular',
          'important'
        );
      });

      if (window.innerWidth >= 500) {
        const customContents = document.querySelectorAll('.custom-content');
        const svDropDown = document.querySelectorAll('.sv-dropdown');

        // Apply the style to each element
        svDropDown.forEach(function (element) {
          element.style.marginRight = '4.5rem';
        });

        // Apply the style to each element
        customContents.forEach(function (element) {
          element.style.position = 'relative';
          element.style.left = 'unset';
          element.style.width = '100%';
        });
      }
    }

    let question = options.question;
    if (question.name !== 'module1actions3/2') return;
    if (document.querySelector('.custom-modal-blue-button')) return; // Prevent multiple button

    // Create the info icon
    let icon = document.createElement('span');
    icon.innerHTML = ' ℹ️';
    icon.className = 'info-icon custom-modal-blue-button';
    icon.style.position = 'absolute';
    icon.style.top = '-5px';
    icon.style.right = '10px';
    icon.style.cursor = 'pointer';
    icon.style.fontSize = '18px';
    icon.style.background = '#fff';
    icon.style.borderRadius = '50%';
    icon.style.padding = '5px';

    // Function to create modal
    function showModal() {
      if (document.querySelector('.custom-modal')) return; // Prevent multiple modals

      const data =
        language === 'english'
          ? `<div>
          <b>Reference: </b>
          <div class="small-font">
            1 serving of vegetables = ½ cup cooked; eg mustard leaf/spinach/water spinach/carrot/cabbage/broccoli
          </div>
          <p class="small-font mt-2">
            1 serving of fruit = 1 slice papaya/pineapple/watermelon or 1 apple/pear or ½ guava
          </p>
        </div>`
          : `<div>
          <b>Rujukan: </b>
          <div class="small-font">
            1 hidangan sayur-sayuran =  ½ cawan sayur yang dimasak; eg sawi/bayam/kangkung/lobak merah/kubis/brokoli​
          </div>
          <p class="small-font mt-2">
            1 hidangan buah-buahan = 1 potong betik/nanas/tembikai atau 1 biji epal/pir or ½ biji jambu batu
          </p>
        </div>`;

      let modal = document.createElement('div');
      modal.className = 'custom-modal';
      modal.innerHTML = data;
      modal.style.position = 'absolute';
      modal.style.background = '#fff';
      modal.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
      modal.style.paddingLeft = '15px';
      modal.style.borderRadius = '8px';
      modal.style.border = '1px solid #ccc';
      modal.style.width = '100%';
      modal.style.right = '10px';
      modal.style.left = '10px';
      modal.style.top = '40px'; // Adjust to appear above desc
      modal.style.zIndex = '100';

      options.htmlElement.appendChild(modal);

      // Close modal when clicking outside
      document.addEventListener('click', function closeModal(event) {
        if (!modal.contains(event.target) && !icon.contains(event.target)) {
          modal.remove();
          document.removeEventListener('click', closeModal);
        }
      });
    }

    // Toggle modal on icon click
    icon.addEventListener('click', function (event) {
      let existingModal = document.querySelector('.custom-modal');
      if (existingModal) {
        existingModal.remove();
      } else {
        showModal();
      }
      event.stopPropagation();
    });

    // Ensure the question container is positioned
    options.htmlElement.style.position = 'relative';
    options.htmlElement.appendChild(icon);
  });
  //Create showdown markdown converter
  var converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);

  survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    // console.log('options', options);
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });

  function onComplete() {
    // console.log('answerArr bbb', answerArr);
    if (Object.keys(answerArr)) {
      let newAnswer = {};
      Object.keys(answerArr).forEach(function (key) {
        newAnswer = { ...newAnswer, ...JSON.parse(answerArr[key]) };
      });
      // console.log('hit here inside func');
      let data = {
        step: step,
        patientId: patientId, // replace this id with real user id
        answer: JSON.stringify(newAnswer),
        lastQuestion: true,
        myType: 'action',
        language: language,
      };
      setSubmitted(true);
      dispatch(answerModuleQuestion(data));
      setShowPopUpAction(false);
    }
  }

  useEffect(() => {
    const data =
      currentStep && Object.keys(allQArr).length && allQArr.pages[currentStep];

    if (currentStep === 0) {
      setCurrentFeedback(allQArr.pages && allQArr.pages[0].questions[0]);
    }

    if (data) {
      let prevQuestion = allQArr.pages[currentStep];
      setCurrentFeedback(prevQuestion.questions[0]);
      setCurrentQLength(data.questions.length);
      setCurrentQuestion(data.questions[0]);
      setAllQuestions({
        requiredText: '',
        pages: [data],
        showQuestionNumbers: 'off',
      });
    }
    if (currentStep > questionLength) {
      let prevQuestion = allQArr.pages && allQArr.pages[questionLength];
      setCurrentFeedback(prevQuestion && prevQuestion.questions[0]);
    }
  }, [currentStep, allQArr]);

  function nextAndBackPage() {
    setMessage('');
    if (currentStep == 0) {
      // only first page
      const answer = JSON.stringify(survey.data, null, 3);
      const newAnswer = JSON.parse(answer);

      if (Object.keys(newAnswer).length !== currentQLength) {
        setShowPopUpAction(false);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return setMessage(
          language === 'english'
            ? 'Please fill all your answers.'
            : 'Sila isi semua jawapan anda.'
        );
      } else {
        let newAns = { ...answerArr };
        newAns[currentStep] = answer;
        setCurrentStep(currentStep + 1);
        setShowPopUpAction(true);
        setAnswerArr(newAns);
      }
    } else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      // set back to fist question
      if (currentStep - 1 === 0) {
        const data = allQArr.pages[0];
        setCurrentQuestion(data.questions[0]);
        setCurrentQLength(data.questions.length);
        setAllQuestions({
          requiredText: '',
          pages: [data],
          showQuestionNumbers: 'off',
        });
      }
    }
  }

  function nextAndComplete() {
    setMessage('');
    setLastQuestion(false);
    const answer = JSON.stringify(survey.data, null, 3);
    let newAns = { ...answerArr };
    const newAnswer = JSON.parse(answer);
    if (currentStep < questionLength) {
      if (Object.keys(newAnswer).length !== currentQLength) {
        if (
          //special case handling for this question section 2
          currentStep === 1 &&
          Object.keys(newAnswer).length === currentQLength - 3
        ) {
          setShowPopUpAction(true);
          newAns[currentStep] = answer;
          setAnswerArr(newAns);
        } else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setShowPopUpAction(false);
          return setMessage(
            language === 'english'
              ? 'Please fill all your answers.'
              : 'Sila isi semua jawapan anda.'
          );
        }
      } else {
        setShowPopUpAction(true);
        newAns[currentStep] = answer;
        setAnswerArr(newAns);
      }
    } else {
      if (Object.keys(newAnswer).length !== currentQLength) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setShowPopUpAction(false);
        return setMessage(
          language === 'english'
            ? 'Please fill all your answers.'
            : 'Sila isi semua jawapan anda.'
        );
      } else {
        newAns[currentStep] = answer;
        setAnswerArr(newAns);
        setShowPopUpAction(true);
        setLastQuestion(true);
      }
    }
  }
  // console.log('currentStep', currentStep, lastQuestion);
  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-body-height">
            <div className="pb-3 position-relative">
              <img
                src={module ? module.photoUrl : ''}
                style={{
                  width: '100%',
                  height: '12rem',
                  objectFit: 'cover',
                }}
                alt="header-img"
              />

              <div className="header-text-render mx-4">
                <div className="trial-header-text mb-2">
                  {t('Trial_Module')}
                </div>
                <div className="main-header-text">
                  {module && module.titleSecond}
                </div>
              </div>
            </div>

            <div>
              <div className="mx-3">
                <div className="sv_progress_bar_block">
                  <div className="green-background"></div>
                  <div className="green-background mx-3"></div>
                  <div className="gray-background"></div>
                </div>

                <div className="pb-3 fw-bold mt-3">
                  {language === 'english'
                    ? 'Complete your personal lifestyle check'
                    : 'Lengkapkan pemeriksaan gaya hidup peribadi anda'}
                </div>

                <div
                  className="fw-bold mt-3 blue-text"
                  style={{ textTransform: 'capitalize' }}
                >
                  {currentQuestion && currentQuestion.headerTitle}
                </div>
                {showPopUpAction && lastQuestion ? null : (
                  <div>{currentQuestion && currentQuestion.mainQuestion}</div>
                )}

                <div className="d-flex justify-content-center mt-3">
                  {allQs &&
                    allQs.map((each, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            width: '25%',
                            height: '5px',
                            marginLeft: '0.3rem',
                            background:
                              each === currentStep ? '#67d841' : '#d9d9d9',
                          }}
                        ></div>
                      );
                    })}
                </div>
              </div>

              <div>
                {message ? (
                  <div className="mx-2">
                    <Message variant="danger" fullWidth={true}>
                      {message}
                    </Message>
                  </div>
                ) : null}
                {currentQuestion &&
                currentQuestion.questionWithSubQuestions &&
                !showPopUpAction ? (
                  <div
                    className=" mt-3 mx-2 fw-bold "
                    style={{ background: '#fff', borderRadius: '10px' }}
                  >
                    <div className="p-2">
                      {currentQuestion &&
                        currentQuestion.questionWithSubQuestions}

                      {currentQuestion.questionWithSubQuestionsImage ? (
                        <div className="text-center">
                          <img
                            className="questionWithSubQuestionsImage"
                            src={
                              currentQuestion &&
                              currentQuestion.questionWithSubQuestionsImage
                            }
                            alt="questionWithSubQuestionsImage"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {showPopUpAction ? (
                  <ModuleFeedbackPage
                    t={t}
                    showPopUpAction={showPopUpAction}
                    setShowPopUpAction={setShowPopUpAction}
                    nextAndBackPage={nextAndBackPage}
                    feedback={currentFeedback}
                    onComplete={onComplete}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    lastQuestion={lastQuestion}
                    language={language}
                  />
                ) : (
                  <>
                    <div>
                      <Survey.Survey
                        model={survey}
                        showCompletedPage={false}
                        showNavigationButtons={false}
                      />
                    </div>

                    <div className="mx-3 mt-3 pb-3">
                      <button
                        onClick={() => nextAndComplete()}
                        className="mt-2 custom-primary-button"
                      >
                        {currentStep === 3
                          ? t('Next')
                          : currentStep === questionLength
                          ? t('ContinueM')
                          : t('Next')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <ButtonNavBar />
          <ContactHelp />
        </>
      )}
    </div>
  );
}

export default ActionModule1;
