import React from 'react';
import ContactHelp from '../view/patient/ContactHelp';
import NavigationPatient from '../view/patient/NavigationPatientHeader';
import { ButtonNavBar } from '../view/patient/buttonNavBar';
import { useTranslation } from 'react-i18next';

const RevampPageLayout = ({
  module,
  headerTitle,
  hideRegisteredWord,
  children,
  smallText,
}) => {
  const { t } = useTranslation();
  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            {hideRegisteredWord ? null : (
              <div className="trial-header-text mb-2">
                {module?.step < 3 ? t('Trial_Module') : t('Registered_Patient')}
              </div>
            )}

            {headerTitle ? (
              <div>
                {smallText ? (
                  <div
                    className="main-header-text mt-5"
                    style={{ width: '80%', fontSize: '1.2rem' }}
                  >
                    {t(headerTitle)}
                  </div>
                ) : (
                  <div
                    className="main-header-text mt-4"
                    style={{ width: '80%' }}
                  >
                    {t(headerTitle)}
                  </div>
                )}
              </div>
            ) : (
              <div className="main-header-text">
                {module && module.titleSecond}
              </div>
            )}
          </div>
        </div>
        {children}
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
};

export default RevampPageLayout;
