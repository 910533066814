import React, { useEffect, useState } from 'react';
import '../../css/patient.css';
import { useSelector } from 'react-redux';
import logoWhite from '../../images/DLP_revamp_asset/logo-header/white-logo-header.png';
import ContactHelp from './ContactHelp';
import backgroundBg from '../../images/DLP_revamp_asset/background-img/background-img-1.png';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ButtonNavBar } from './buttonNavBar';
import axios from 'axios';
import { tokenConfig } from '../../actions/actionHelperFunc';
import { useTranslation } from 'react-i18next';

const PatientPublicLandingPage = () => {
  const { t } = useTranslation();
  const [percentCount, setPercentCount] = useState(0);
  const { patient } = useSelector((state) => state.patientR);

  useEffect(() => {
    if (patient) {
      axios
        .post(
          `/api/patient/public/patient/details`,
          { patientId: patient?.id },
          tokenConfig()
        )
        .then((res) => {
          const user = res.data.data;
          if (user.moduleTracker.length) {
            const percent = (user.moduleTracker.length / 21) * 100;
            // console.log('percent', user.moduleTracker);
            setPercentCount(Number(percent.toFixed(2)));
          }
        })
        .catch((err) => {
          console.log('err', err.response);
        });
    }
  }, [patient]);

  // console.log('patient', patient);

  return (
    <div
      className="main-wrapper-div"
      style={{ maxWidth: '500px', margin: '0 auto' }}
    >
      <div
        className="main-body-height"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${backgroundBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          with: '100%',
          margin: '0',
          height: 'auto',
          position: 'relative',
        }}
      >
        <div
          className="text-end shadow"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            width: '100%',
          }}
        >
          <img src={logoWhite} alt="logoWhite" className="me-3 mt-3" />
        </div>

        <div
          style={{
            textAlign: 'center',
            marginTop: '2rem',
          }}
        >
          <div className="patient-header-text">{patient?.firstName}</div>
          <div className="patient-header-text mt-5">
            {t('You are on track!')}
          </div>
          <div className="mt-5" style={{ width: '65%', margin: '0 auto' }}>
            <CircularProgressbarWithChildren
              value={percentCount}
              strokeWidth={5}
              styles={buildStyles({
                pathColor: '#66B072',
                trailColor: 'transparent',
              })}
            >
              <div style={{ width: '90%' }}>
                <CircularProgressbar
                  strokeWidth={3}
                  value={100}
                  text={`${percentCount}%`}
                  styles={buildStyles({
                    pathColor: 'rgba(217, 217, 217, 0.5)',
                    trailColor: 'transparent',
                    textColor: '#fff',
                    textSize: '1.3rem',
                  })}
                />
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>

      <ButtonNavBar publicPatient={true} />
      <ContactHelp />
    </div>
  );
};

export default PatientPublicLandingPage;
