import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import showdown from 'showdown';
import {
  getModuleByStep,
  answerModuleQuestion,
  getModuleQuestionsByType,
  getModuleAnswer,
} from '../../../actions/moduleActions';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import '../../../index.css';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useTranslation } from 'react-i18next';
import ContactHelp from '../ContactHelp';
import NavigationPatient from '../NavigationPatientHeader';
import { ButtonNavBar } from '../buttonNavBar';

function ActionModule6({ history }) {
  useEffect(() => {
    var refresh = window.localStorage.getItem('refresh');
    if (!refresh) {
      window.location.reload();
      window.localStorage.setItem('refresh', '1');
    }
  }, []);

  const step = 6;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.fontR);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const patientId = urlParams.get('patientId');
    if (patientId) {
      setPatientId(patientId);
    }
    if (step) {
      dispatch(getModuleAnswer({ step, myType: 'action', patientId }));
      dispatch(getModuleByStep({ step, myType: 'action', language }));
      dispatch(getModuleQuestionsByType({ step, myType: 'action', language }));
    }
  }, [language, dispatch]);

  const { module, questions, moduleAnswer, loading } = useSelector(
    (state) => state.moduleR
  );
  const [allQuestions, setAllQuestions] = useState({
    requiredText: '',
    pages: [],
    showQuestionNumbers: 'off',
  });
  const [submitted, setSubmitted] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentStep] = useState(0);
  const [allQArr, setAllQArr] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage('');
    let questionsFiltered = [];
    if (questions && questions.length) {
      let json = {
        requiredText: '',
        pages: [],
        showQuestionNumbers: 'off',
      };

      questionsFiltered = questions;

      const newArr = {};
      const allR = [];
      questionsFiltered.forEach((q, index) => {
        newArr[index] = {}; // set answer object
        allR.push(index);
        let sortedD;
        if (q.length > 1) {
          sortedD = q.sort((a, b) => a.rank - b.rank);
        } else {
          sortedD = [q];
        }
        json.pages.push({ questions: sortedD });
      });

      setAllQuestions(json);
      setAllQArr(json);

      if (json.pages.length) {
        setCurrentQuestion(json.pages[0] && json.pages[0].questions[0]);
      }
    }
  }, [questions]);

  useEffect(() => {
    if (moduleAnswer && submitted) {
      return history.push(
        '/patient/action/module6/success' + window.location.search
      );
    }
  }, [moduleAnswer, submitted]);

  var survey = new Survey.Model(allQuestions);
  survey.locale = language === 'malay' ? 'id' : 'en';
  Survey.StylesManager.applyTheme('modern');

  survey.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;

    console.log('options.question.getType()', options.question.getType());

    classes.mainRoot += ' sv_qstn-action';
    classes.root = ' sq-root-action';
    classes.title += ' sq-title';
    classes.item += ' sq-item';
    classes.label += ' sq-label';
    classes.number += ' action-question_num';
    classes.description += ' sv_action_description';
    if (options.question.getType() === 'text') {
      classes.root += ' sq-root-m5';
      classes.description +=
        ' sv_action_description module6-custom-description';
    }
    if (options.question.isRequired) {
      classes.title += ' sq-title-required';
      classes.root += ' sq-root-required';
    }
  });

  var converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);

  survey.onTextMarkdown.add(function (survey, options) {
    var str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  });

  const submitModule6 = () => {
    if (moduleAnswer) {
      return history.push(
        '/patient/action/module6/success' + window.location.search
      );
    }

    let data = {
      step: step,
      patientId: patientId, // replace this id with real user id
      answer: {},
      lastQuestion: true,
      myType: 'action',
      language: language,
    };
    setSubmitted(true);
    dispatch(answerModuleQuestion(data));
  };

  useEffect(() => {
    const data =
      currentStep && Object.keys(allQArr).length && allQArr.pages[currentStep];
    if (data) {
      setCurrentQuestion(data.questions[0]);
      setAllQuestions({
        requiredText: '',
        pages: [data],
        showQuestionNumbers: 'off',
      });
    }
  }, [currentStep, allQArr]);

  return (
    <div className="small-screen-center main-wrapper-div">
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: '10',
          maxWidth: '500px',
        }}
      >
        <NavigationPatient />
      </div>
      {loading ? <Loader /> : null}

      <div className="main-body-height">
        <div className="pb-3 position-relative">
          <img
            src={module ? module.photoUrl : ''}
            style={{
              width: '100%',
              height: '12rem',
              objectFit: 'cover',
            }}
            alt="header-img"
          />

          <div className="header-text-render mx-4">
            <div className="trial-header-text mb-2">
              {t('Registered_Patient')}
            </div>
            <div className="main-header-text">
              {module && module.titleSecond}
            </div>
          </div>
        </div>

        <div className="main-body-height mx-3">
          <div className="sv_progress_bar_block ">
            <div className="green-background"></div>
            <div className="green-background mx-3"></div>
            <div className="gray-background"></div>
          </div>

          <div className="mt-3">
            <h3 className="fw-bold">{module && module.actionTitle}</h3>
            <div className="py-3">
              {currentQuestion && currentQuestion.headerTitle}
            </div>
            <div>{currentQuestion && currentQuestion.mainQuestion}</div>
          </div>

          {message ? (
            <div className="mx-2 py-2">
              <Message variant="danger" fullWidth={true}>
                {message}
              </Message>
            </div>
          ) : null}

          <Survey.Survey
            model={survey}
            showCompletedPage={false}
            showNavigationButtons={false}
          />

          <div className=" mx-3 mt-3 pb-3">
            <button
              onClick={() => submitModule6()}
              className="mt-2 custom-primary-button"
            >
              {t('ContinueM')}
            </button>
          </div>
        </div>
      </div>

      <ButtonNavBar />
      <ContactHelp />
    </div>
  );
}

export default ActionModule6;
