import {
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  GET_DOCTOR_SUCCESS,
  GET_DOCTOR_FAIL,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAIL,
  SET_DOCTOR_LOADING,
  CLEAR_DOCTOR_ERROR,
  GET_DOCTOR_COMMENTS_SUCCESS,
  GET_DOCTOR_COMMENTS_FAIL,
} from './types';

const initialState = {
  loading: false,
  doctor: null,
  verify: false,
  updated: false,
  doctors: [],
};

const doctorReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_DOCTOR_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_DOCTOR_ERROR:
      return {
        ...state,
        error: null,
        success: null,
      };

    // user login and registration success
    case GET_DOCTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        verify: true,
        doctor: action.payload,
      };

    case GET_DOCTORS_SUCCESS:
      return {
        doctors: action.payload,
        loading: false,
        error: false,
        verify: true,
      };

    case UPDATE_DOCTOR_SUCCESS:
      return {
        ...state,
        doctor: action.payload,
        loading: false,
        updated: true,
      };

    case GET_DOCTOR_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };

    // user auth error and logout
    case GET_DOCTOR_FAIL:
    case GET_DOCTORS_FAIL:
    case UPDATE_DOCTOR_FAIL:
    case GET_DOCTOR_COMMENTS_FAIL:
      return {
        loading: false,
        updated: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default doctorReducer;
