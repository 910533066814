import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import '../../css/modal.css';
import { Link } from 'react-router-dom';
import popUp4 from '../../images/bg/patientImg/app-4-popup.png';

class App_Four_popup extends Component {
  isShowModal = () => {
    this.props.onPopupClose(true);
  };

  handleClose = () => {
    localStorage.setItem('hideAppointmentPopup', true);
    return (window.location.href = '/patient/learn module/main');
  };

  render() {
    return (
      <Modal
        show={this.props.showModalPopup}
        onHide={this.handleClose}
        size="lg"
        centered
      >
        <Modal.Body>
          <center>
            <img
              src={popUp4}
              style={{ width: '100%', height: '15rem', objectFit: 'contain' }}
              alt="success"
            />
          </center>
          <div style={{ textAlign: 'center', margin: '1rem' }}>
            <div>
              {this.props.t('completeT1')} <br />
              {this.props.t('completeT2')} <br />
              <div className="mt-3"></div>
              {this.props.t('completeT3')} <br />
              {this.props.t('completeT4')}
            </div>

            <div className="mt-3">
              <Link
                onClick={() =>
                  localStorage.setItem('hideAppointmentPopup', true)
                }
                to={`/patient/record/appointment`}
              >
                <button className="custom-primary-button">
                  {this.props.t('Yes')}
                </button>
              </Link>
              <div className="mt-3">
                <button
                  className="custom-transparent-button"
                  onClick={() => this.handleClose()}
                >
                  {this.props.t('MaybeLater')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default App_Four_popup;
