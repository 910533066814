import React from 'react';
import { Alert } from 'react-bootstrap';

const Message = ({ variant, timeout, children, fullWidth }) => {
  if (fullWidth) {
    return (
      <div className="mt-2 mx-auto w-100">
        <Alert variant={variant}>{children}</Alert>
      </div>
    );
  } else {
    return (
      <div className="mt-2 mx-auto w-50">
        <Alert variant={variant}>{children}</Alert>
      </div>
    );
  }
};

export default Message;
