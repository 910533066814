import i18n from '../i18n';

const patientLang = localStorage.getItem('patientLanguage');
const localLang = localStorage.getItem('language');

const initState = {
  fontSize: localStorage.getItem('dlp-font-size') || 'smallfont',
  language: localStorage.getItem('doctorLanguage')
    ? 'english'
    : patientLang
    ? patientLang || 'english'
    : localLang || 'english',
};

const fontReducer = (state = initState, action) => {
  switch (action.type) {
    case 'BIG_FONT':
      return { ...state, fontSize: 'bigfont' };
    case 'SMALL_FONT':
      return { ...state, fontSize: 'smallfont' };
    case 'CHANGE_LANGUAGE':
      localStorage.setItem(
        'language',
        action.payload === 'my' ? 'malay' : 'english'
      );
      localStorage.setItem('i18nextLng', action.payload);
      i18n.changeLanguage(action.payload);
      return {
        ...state,
        language: action.payload === 'my' ? 'malay' : 'english',
      };
    default:
      return state;
  }
};
export default fontReducer;
