import axios from 'axios';
import {
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAIL,
  GET_MODULE_SUCCESS,
  GET_MODULE_FAIL,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  SET_MODULE_LOADING,
  CLEAR_MODULE_ERROR,
  GET_MODULE_ANSWER_SUCCESS,
  GET_MODULE_ANSWER_FAIL,
  GET_MODULE_QUESTIONS_SUCCESS,
  GET_MODULE_QUESTIONS_FAIL,
  ADD_MODULE_ANSWER_SUCCESS,
  ADD_MODULE_ANSWER_FAIL,
  GET_MODULE_RE_QS_SUCCESS,
  GET_MODULE_RE_QS_FAIL,
  GET_DUAL_MODULE_QUESTIONS_SUCCESS,
  GET_DUAL_MODULE_QUESTIONS_FAIL,
} from '../reducers/types';
import { returnError, tokenConfig } from './actionHelperFunc';

export const getModuleByStep = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_MODULE_ERROR });
    dispatch({ type: SET_MODULE_LOADING });

    const { data } = await axios.post(
      `/api/module/getModuleByStep`,
      reqBody,
      tokenConfig(getState)
    );
    // console.log(data);
    dispatch({
      type: GET_MODULE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_MODULE_FAIL, payload: returnError(error) });
  }
};

export const getModuleQuestionsByType =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_MODULE_ERROR });
      dispatch({ type: SET_MODULE_LOADING });

      const { data } = await axios.post(
        `/api/module/getModuleQuestionsByType`,
        reqBody,
        tokenConfig(getState)
      );

      dispatch({
        type: GET_MODULE_QUESTIONS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MODULE_QUESTIONS_FAIL,
        payload: returnError(error),
      });
    }
  };

export const getDualModuleQuestionsByType =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_MODULE_ERROR });
      dispatch({ type: SET_MODULE_LOADING });

      const { data } = await axios.post(
        `/api/module/getDualModuleQuestionsByType`,
        reqBody,
        tokenConfig(getState)
      );

      dispatch({
        type: GET_DUAL_MODULE_QUESTIONS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DUAL_MODULE_QUESTIONS_FAIL,
        payload: returnError(error),
      });
    }
  };

export const getModuleQuestionsForReflect =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_MODULE_ERROR });
      dispatch({ type: SET_MODULE_LOADING });

      const { data } = await axios.post(
        `/api/module/getModuleQuestionsForReflect`,
        reqBody,
        tokenConfig(getState)
      );

      dispatch({
        type: GET_MODULE_RE_QS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MODULE_RE_QS_FAIL,
        payload: returnError(error),
      });
    }
  };

export const getAllModules = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_MODULE_ERROR });
    dispatch({ type: SET_MODULE_LOADING });

    const { data } = await axios.post(
      `/api/module/getModules`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_MODULES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_MODULES_FAIL, payload: returnError(error) });
  }
};

export const updateModule = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_MODULE_ERROR });
    dispatch({ type: SET_MODULE_LOADING });

    const { data } = await axios.post(
      `/api/module/updateModule`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: UPDATE_MODULE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: UPDATE_MODULE_FAIL, payload: returnError(error) });
  }
};

export const answerModuleQuestion = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_MODULE_ERROR });
    dispatch({ type: SET_MODULE_LOADING });

    const { data } = await axios.post(
      `/api/module/answerQuestion`,
      reqBody,
      tokenConfig(getState)
    );
    dispatch({
      type: ADD_MODULE_ANSWER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: ADD_MODULE_ANSWER_FAIL, payload: returnError(error) });
  }
};

export const answerModule4Question =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_MODULE_ERROR });
      dispatch({ type: SET_MODULE_LOADING });

      const { data } = await axios.post(
        `/api/module/answerModule4Question`,
        reqBody,
        tokenConfig(getState)
      );
      dispatch({
        type: ADD_MODULE_ANSWER_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: ADD_MODULE_ANSWER_FAIL, payload: returnError(error) });
    }
  };

export const answerModule9Question =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_MODULE_ERROR });
      dispatch({ type: SET_MODULE_LOADING });

      const { data } = await axios.post(
        `/api/module/answerModule9Question`,
        reqBody,
        tokenConfig(getState)
      );
      dispatch({
        type: ADD_MODULE_ANSWER_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: ADD_MODULE_ANSWER_FAIL, payload: returnError(error) });
    }
  };

export const getModuleAnswer = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_MODULE_ERROR });
    dispatch({ type: SET_MODULE_LOADING });

    const { data } = await axios.post(
      `/api/module/getModuleSingleAnswer`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_MODULE_ANSWER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_MODULE_ANSWER_FAIL, payload: returnError(error) });
  }
};
