import React, { useState } from 'react';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Container, Button } from 'react-bootstrap';
// bg image
import user from '../../images/DLP_revamp_asset/Icon/private-login-icon.png';
// Icon Image
import { useTranslation } from 'react-i18next';
import ContactHelp from './ContactHelp';
import { ModalPatient } from './ModalPatient';
import axios from 'axios';
import Rheader from '../../components/Rheader';
import { useSelector } from 'react-redux';
import privacyPolicy from '../../images/DLP Pt Web App Privacy Policy & Terms of Use 20241202.pdf';
import privacyPolicyBM from '../../images/DLP Pt Web App Privacy Policy & Terms of Use 20241202 (BM).pdf';

const PublicRegistrationPage = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.fontR);

  const public_page_user_id = localStorage.getItem('dlp-public-user-id');
  const [firstName, setFirstName] = useState('');
  const [DOB, setDOB] = useState(null);
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [consent, setConsent] = useState(false);

  const handleDateChange = (date) => {
    setDOB(date);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      type="text"
      placeholder={t('Date_of_Birth')}
      onClick={onClick}
      ref={ref}
      defaultValue={value}
    />
  ));

  function handleSubmit(event) {
    event.preventDefault();

    const reqBody = {
      firstName,
      DOB: new Date(DOB),
      gender,
      email,
      phoneNumber: localStorage.getItem('patient_Number'),
      public_page_user_id,
    };

    axios
      .post('/api/auth/public/patient/register', reqBody)
      .then((res) => {
        setShowSuccessModal(true);
        // console.log('res.data.data', res.data.data);
        localStorage.setItem('dlp-user-token', res.data.data.token);
      })
      .catch((err) => {
        console.log('err', err.response);
        setShowFailureModal(true);
      });
  }

  const FormRegister = () => {
    return (
      <Container style={{ marginTop: '20px' }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFullName">
            <Form.Control
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              placeholder={t('First_Name')}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDateOfBirth" className="date-of-birth">
            <DatePicker
              selected={DOB}
              onChange={handleDateChange}
              customInput={<CustomInput />}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={new Date(1600, 0)}
              maxDate={new Date(2024, 11)}
              dateFormat="yyyy/MM/dd"
              required
            />
          </Form.Group>
          <Form.Group
            controlId="formGender"
            className="form-group gender-select"
          >
            <Form.Control
              onChange={(e) => setGender(e.target.value)}
              as="select"
              className="select-gender"
              required
            >
              <option value="">{t('Gender')}*</option>
              <option value="Male">{t('Male')}</option>
              <option value="Female">{t('Female')}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formEmailAddress">
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder={t('Email_Address')}
              required
            />
          </Form.Group>
          <div>
            <h3 className="w-full d-flex justify-content-center align-items-center">
              {t('disclaimerTitle')}
            </h3>
            <p
              id="disclaimer-title-text"
              className="text-center line-height-18px fw-light"
            >
              {t('disclaimerDescription')}
            </p>
          </div>
          <div className="d-flex">
            <Form.Check
              value={consent}
              onChange={(e) => setConsent(!consent)}
              aria-label="option 2"
              required
              className="d-flex justify-content-center align-items-center"
            />
            <div className="d-flex flex-column">
              <p className="m-0 line-height-18px">
                {t('i_acknowledge')} &nbsp;
              </p>
              <a
                href={language === 'english' ? privacyPolicy : privacyPolicyBM}
                target="_blank"
                style={{ textDecoration: 'underline' }}
                rel="noreferrer"
                className="line-height-18px"
              >
                {t('privacyAndTerms')}
              </a>
            </div>
          </div>

          <div className="py-3">
            <i>{t('disclaimer_description')} </i>
          </div>
          <Button className="custom-primary-button mb-3" type="submit">
            {t('Submit')}
          </Button>
        </Form>
      </Container>
    );
  };

  return (
    <div className="mobile-view">
      <div className="main-wrapper-div">
        <Rheader language={language} />
        <div className="main-body-content-page">
          <div className="register-image-banner">
            <img className="" src={user} alt="user register" />
          </div>
          <div>
            <div className="font-main-header-bold mt-4 text-center">
              <h1 style={{ fontWeight: '800' }}>{t('Registration')}</h1>
            </div>
            <div className="normal-line-height mt-2 text-center mb-2">
              <b>{t('fill_in_the_form')}.</b>
            </div>
          </div>
          <div>{FormRegister()}</div>
        </div>
        <ContactHelp />
        <ModalPatient
          isVisible={showSuccessModal}
          isSuccessful={true}
          onClose={() => {
            setShowSuccessModal(false);
            window.location.href = '/patient/public/landing';
          }}
          centered={true}
        />
        <ModalPatient
          isVisible={showFailureModal}
          onClose={() => setShowFailureModal(false)}
        />
      </div>
    </div>
  );
};

export default PublicRegistrationPage;
