import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../css/patient_profile.css';
import '../../index.css';
import { useSelector } from 'react-redux';
import LearnIcon from '../../../src/images/DLP_revamp_asset/Icon/learn-button.svg';
import DietitianIcon from '../../../src/images/DLP_revamp_asset/Icon/dietitian-button.svg';
import ProfileIcon from '../../../src/images/DLP_revamp_asset/Icon/profile-button.svg';
import LearnIconActive from '../../../src/images/DLP_revamp_asset/Icon/action-svg-clicked.svg';
import DietitianIconActive from '../../../src/images/DLP_revamp_asset/Icon/dietitian-button-clicked.svg';
import ProfileIconActive from '../../../src/images/DLP_revamp_asset/Icon/profile-button-clicked.svg';
import '../../css/ButtonNavBar.css';

export const ButtonNavBar = ({ publicPatient }) => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [activeButton, setActiveButton] = useState(location.pathname);
  const { patient } = useSelector((state) => state.patientR);

  const handleButtonClick = (path) => {
    setActiveButton(path); // Set the active button on click
    history.push(path);
  };

  const isActiveLearn = (pathname) => {
    const paths = [
      '/patient/learn module/main',
      '/patient/learn module/main/preview',
      '/patient/learn_module',
      '/patient/public/home',
      '/patient/action/',
      '/patient/module',
      '/patient/video/',
    ];

    return paths.some((path) => pathname.startsWith(path));
  };

  function isActiveDietitian(pathname) {
    const paths = ['/patient/record/appointment'];

    return paths.some((path) => pathname.startsWith(path));
  }

  function isActiveMeProfile(pathname) {
    const paths = [
      '/patient/user-profile',
      '/patient/user profile',
      '/patient/user_profile',
      '/address',
      '/patient/antrhop details',
      '/patient/record/progress',
      '/patient/medical_profile',
      '/patient/view/answer',
    ];

    return paths.some((path) => pathname.startsWith(path));
  }

  return (
    <div className={`bottom-nav-container ${i18n.language}`}>
      <div
        className="bottom-nav-item"
        onClick={() =>
          publicPatient ||
          (patient?.public_registered_user && !patient?.doctorId)
            ? history.push('/patient/public/home')
            : handleButtonClick('/patient/learn module/main')
        }
      >
        <span
          className={`nav-icon-wrapper ${
            isActiveLearn(location.pathname) ? 'active' : ''
          }`}
        >
          <img
            src={isActiveLearn(location.pathname) ? LearnIconActive : LearnIcon}
            className="nav-icon images"
            alt="icon"
          />
        </span>
        <div
          className={`nav-text font-normal-navbar-bold ${
            isActiveLearn(location.pathname) ? 'active' : ''
          }`}
        >
          {t('HOMEPAGE')}
        </div>
      </div>

      <div
        className="bottom-nav-item"
        onClick={() => handleButtonClick('/patient/record/appointment')}
      >
        <span
          className={`nav-icon-wrapper ${
            isActiveDietitian(location.pathname) ? 'active' : ''
          }`}
        >
          <img
            src={
              isActiveDietitian(location.pathname)
                ? DietitianIconActive
                : DietitianIcon
            }
            className="nav-icon images"
            alt="icon"
          />
        </span>
        <div
          className={`nav-text font-normal-navbar-bold ${
            isActiveDietitian(location.pathname) ? 'active' : ''
          }`}
        >
          {t('bookADietitian')}
        </div>
      </div>

      <div
        className="bottom-nav-item"
        onClick={() => handleButtonClick('/patient/user-profile')}
      >
        <span
          className={`nav-icon-wrapper ${
            isActiveMeProfile(location.pathname) ? 'active' : ''
          }`}
        >
          <img
            src={
              isActiveMeProfile(location.pathname)
                ? ProfileIconActive
                : ProfileIcon
            }
            className="nav-icon images"
            alt="icon"
          />
        </span>
        <div
          className={`nav-text font-normal-navbar-bold ${
            isActiveMeProfile(location.pathname) ? 'active' : ''
          }`}
        >
          {t('ME')}
        </div>
      </div>
    </div>
  );
};
